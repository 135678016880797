.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;

  &__image {
    max-width: 150px;
  }

  &__title {
    font-size: 24px;
    line-height: 150%;
    font-weight: 400;
    max-width: 350px;
    text-align: center;

  }
}