.content {
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 750px;
  flex: 1;
  position: relative;

  .container {
    display: flex;
    flex-direction: column;
    padding: 10px 0 20px 0;

    .statistics_top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      align-items: flex-end;
      gap: 8px;

      .label {
        margin: 0;
        text-align: start;
      }

      .buttons_container {
        display: flex;
        gap: 8px;
        width: calc(25% - 4px);

        .mode_btn {
          width: 50%;
          border-color: #CEE5F0;
        }
      }
    }
  }

  .button {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    min-height: 100%;
    z-index: 2;
  }

  .label {
    font-size: 10px;
    line-height: 150%;
    color: #58717F;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .statistics {
    display: flex;
    width: 100%;
    column-gap: 8px;
    max-height: 192px;

    .index {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 30px;
      gap: 8px;
      border: 1px solid #CEE5F0;
      border-radius: 6px;

      &__icon {
        display: flex; align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background-color: #6AB0F5;
        color: #FFFFFF;
        margin-bottom: 8px;
        border-radius: 100%;

        svg {
          width: 40%;
          height: 40%;
        }
      }

      &__title {
        color: #58717F;
        font-size: 13px;
        font-weight: 400;
        line-height: 155%;
      }

      &__value {
        color: #13163A;
        font-size: 18px;
        font-weight: 400;
        line-height: 121%;

      }

      &__grade {
        color: #62D294;
        background-color: #E7F8EF;
        border-radius: 4px;
        font-size: 13px;
        line-height: 100%;
        font-weight: 400;
        padding: 4px 15px;
        text-align: center;
        width: max-content;
      }
    }
  }

  .button_wrapper {
    display: flex;
    position: relative;
    justify-content: center;
    margin-top: 10px;
  }
}

.blur {
  filter: blur(4px);
}

.notification {
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
  padding: 5px 15px;
  border-radius: 5px;
  height: 0;
  transition: height 0.3s;
  width: max-content;
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;

  &__success {
    height: 26px;
    color: white;
    background-color: #62D294;
  }

  &__error {
    height: 26px;
    color: white;
    background-color: #ff4d4f;
  }
}