.error_page {
  display: flex;
  min-height: 100vh;
  flex: 1;
  justify-content: center;
  align-items: center;

  .main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .title {
      font-size: 20px;
      line-height: 120%;
    }
  }
}