.text_field {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  .label {
    font-size: 10px;
    line-height: 150%;
    color: #58717F;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .input {
    outline: none;
    font-family: inherit;
    border: 1px solid #CEE5F0;
    border-radius: 6px;
    height: 42px;
    padding: 0 15px;
    font-size: 13px;
    font-weight: 400;
    background-color: #FFFFFF;
    cursor: pointer;

    &[aria-invalid='true'] {
      border-color: red;
    }

    &:disabled {
      opacity: .5;
      cursor: no-drop;
    }
  }

  .error {
    color: red;
    font-size: 12px;
    font-weight: 300;
    background-color: white;
    z-index: 9;
  }
}