.home_page {
  display: flex;
  flex: 1;

  .main {
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 30px;

    .search_form {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      max-width: 380px;
      width: 100%;
    }
  }

  .side {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    flex: 1;
    background-color: #F9FAFB;
    margin: -35px -35px -35px 0;
    padding: 35px 35px 35px;

  }
}