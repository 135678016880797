.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  background-color: #4288F0;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 500;
  line-height: 121%;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0 20px;


  &:disabled {
    opacity: .5;
    cursor: no-drop;
  }


  & > svg {
    width: 11px;
    height: 11px;
    margin-right: 9px;
  }

  &__outlined {
    color: #4288F0;
    background-color: #FFFFFF;
    border-color: #E7F2F7;
    font-weight: 400;
  }
}