.feedback_page {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;


  .feedback_form {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    width: 100%;
    padding: 50px;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 1px 5px 10px rgba(21, 30, 34, 0.2);
    row-gap: 15px;

    &__title {
      font-size: 27px;
      line-height: 130%;
      font-weight: 500;
      color: #13131B;
      margin-bottom: 5px;
    }
  }
}