.loader {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  align-items: center;
  justify-content: center;
  width: max-content;

  &__wheel {
    width: 33px;
    height: 33px;
    border: solid 6px #4288F0;
    border-bottom-color: #E8EAEB;
    border-radius: 50%;
    display: inline-block;

    animation-name: spinner;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 145%;
    color: #13131B;
    text-align: center;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}