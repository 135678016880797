.keywords_input {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  .label {
    font-size: 10px;
    line-height: 150%;
    color: #58717F;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    font-family: inherit;
    border: 1px solid #CEE5F0;
    border-radius: 6px;
    min-height: 200px;
    max-height: 350px;
    padding: 10px;
    font-size: 13px;
    font-weight: 400;
    background-color: #FFFFFF;
    cursor: pointer;
    overflow-y: auto;

    .html_input {
      outline: none;
      padding: 0;
      margin: 0;
      border: none;
      font-size: inherit;
      width: 100%;
    }


    &__invalid {
      border-color: red;
    }
  }


  .error {
    color: red;
    font-size: 12px;
    font-weight: 300;
    background-color: white;
    z-index: 9;
  }
}

.keyword {
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 143%;
  height: 32px;
  text-transform: capitalize;
  padding: 0 11px;
  background-color: #FAFCFD;
  border: 4px;
  cursor: pointer;
  color: #13131B;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background-color: #58717F;
    margin-right: 5px;
    color: #FFFFFF;
    opacity: 0.5;

    svg {
      width: 50%;
      height: 50%;
    }
  }

  &__checked {
    .icon {
      background-color: #4288F0;
      opacity: 1;
    }
  }
}