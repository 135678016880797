.layout {
  display: flex;
  height: 100vh;
  padding: 35px;
  flex-direction: column;

  .to_back {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 14px;
    line-height: 116%;
    color: #214254;
    cursor: pointer;

    svg {
      opacity: .5;
      width: 15px;
    }
  }
}