.keywords_page {
  display: flex;
  flex: 1;


  .main {
    display: flex;
    flex: 2;
    justify-content: center;
    align-items: center;

    .content {
      display: flex;
      flex-direction: column;
      max-width: 380px;
      width: 100%;


      .app_name {
        font-size: 11px;
        line-height: 165%;
        font-weight: 400;
        color: #4288F0;
        letter-spacing: 1px;
        margin-bottom: 15px;
      }

      .description {
        margin-top: 8px;
        color: #214254;
        font-size: 14px;
        line-height: 180%;
        margin-bottom: 25px;
      }

      .keywords_form {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        width: 100%;
      }
    }
  }

  .side {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    flex: 1;
    background-color: #F9FAFB;
    margin: -35px -35px -35px 0;
    padding: 35px 35px 35px;
  }
}