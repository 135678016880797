.custom_tooltip {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  line-height: 125%;
  background-color: #FFFFFF;
  opacity: .6;
  border-radius: 10px;
  font-size: 16px;
  box-shadow: 1px 5px 10px rgba(21, 30, 34, 0.2);

  &__label {
    font-weight: 500;
    opacity: 1;
  }

  &__value {
    font-weight: 400;
    opacity: 1;
  }
}